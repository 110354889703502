/* z-index: -1; */

.button-shape {
  position: relative;
  bottom: 30vh;
}

@media (max-width: 500px) {
  .button-shape {
    position: relative;
    bottom: 20vh;
  }
}

@media (max-width: 350px) {
  .button-shape {
    position: relative;
    bottom: 15vh;
  }
}

.follow__me__btn {
  font-family: "El Messiri", sans-serif;
  font-size: 1.8vw;
  background-color: #a0d1cc;
  border: none;
  outline: none;
  color: #ffffff;
  padding: 0.7vh 5vw;
  border-radius: 5vw;
  cursor: pointer;
}

@media (max-width: 768px) {
  .follow__me__btn {
    font-size: 21px;
    padding: 0.7vh 5rem;
  }
}

@media (max-width: 500px) {
  .follow__me__btn {
    font-size: 14px;
    padding: 0.7vh 2rem;
  }
}
