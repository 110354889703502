@import url("https://fonts.googleapis.com/css2?family=Lexend+Mega&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lalezar&family=Lexend+Mega&display=swap");
@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Almarai&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aref+Ruqaa:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tajawal&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Amiri:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Changa&display=swap");
@import url("https://fonts.googleapis.com/css2?family=El+Messiri&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Markazi+Text&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reem+Kufi&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lemonada&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mada&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Scheherazade&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lateef&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Harmattan&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kufam&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mirza&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rakkas&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Katibeh&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jomhuria&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vibes&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("/public/images/background.png");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.overview-sl {
  background-image: url("/public/images/home_picture.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.card-container {
  max-width: 72rem;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 23fr;
  grid-template-rows: 23fr;
  -ms-grid-columns: (minmax(180px, 1fr)) [auto-fill];
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 7rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-items: center;
}

.pic-fit {
  object-fit: cover;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContentBlog {
  bottom: -50px;
}

/* Show the dropdown menu on hover */
.dropdownBlog .dropdownContentBlog {
  display: block;
}

/* Change tailwind after (start)*/
.FormGroup {
  padding: 0;
  border-style: none;
  background-color: #efb7b5;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #efb7b5;
  border-radius: 4px;
  color: #5c5c5c;
}

.FormRow {
  color: #5c5c5c;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.stripe_button {
  display: block;
  font-size: 16px;
  width: 100%;
  height: 50px;
  margin-top: 1rem;
  background-color: #5c5c5c;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #5c5c5c;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  border: none;
}
.stripe_button:active {
  background-color: #5c5c5c;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #5c5c5c;
  transform: scale(0.99);
}

.FormGroup h1,
.FormGroup h3 {
  text-align: center;
}
