 .nabda__section{
    background-color: #5B5B5B;
    width: 90%;
    margin: auto;
    overflow: hidden;
    max-height: 4vw;
    border-radius: 10vw;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

 .nabda__section p{
    color: #ffffff;
    font-size: 2vw;
    font-family: 'Lalezar', cursive;
    z-index: 1;

}

 .nabda__section img{
    position: absolute;
    z-index: 0;
    max-height: 100%;
}


@media(max-width: 768px){


   .nabda__section{
        max-height: 3rem;
        min-height: 3rem;
        border-radius: 10vw;
        
    }

   .nabda__section p{
        font-size: 1.5rem;

    }

}