/* subscribe section  */

.subscribe__section{
    position: relative;
    background-color: #5B5B5B;
    min-height: 23vw;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.subscribe__section .subscribe__flower{
    position: absolute;
    width: 15vw;
}


.subscribe__section #topRightFlower{
    top: 0;
    right: 0;
    transform: translateX(50%) translateY(-2vh);
}


.subscribe__section #bottomLeftFlower{
    bottom: 0;
    left: 0;
    transform: translateX(-40%) translateY(2vh);

}


.subscribe__section .subscribe__container{
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 20vw;
    justify-content: space-between;
}


.subscribe__section .subscribe__container .subscribe__title{
    font-size: 2vw;
    font-family: 'Lalezar', cursive;
    color: #EFB7B5;
}


.subscribe__section .subscribe__container .subscribe__description{
    text-align: center;
    color: #ffffff;
    font-size: 1.6vw;
    font-family: 'El Messiri', sans-serif;
    padding: 0 2vw;

}


.subscribe__section .subscribe__container .subscribe__form{
    text-align: end;
    margin: auto 3vw;
    
}

.subscribe__section .subscribe__container .subscribe__form form {
    display: flex;
    flex-direction: row-reverse;
    height: 3.5vw;
}

.subscribe__section .subscribe__container .subscribe__form form input[type="email"]{
    background: none;
    outline: none;
    border: 1px #EFB7B5 solid;
    color: #C4C4C4;
    font-family: 'El Messiri', sans-serif;
    font-size: 1.4vw;
    padding: 0 1vw;
    border-radius: 0;
}

.subscribe__section .subscribe__container .subscribe__form form input[type="submit"]{
    background-color: #EFB7B5;
    border: none;
    outline: none;
    color: #ffffff;
    font-family: 'Arslan-Wessam-B', sans-serif;
    font-size: 1.6vw;
    padding: 0 1.3vw;
    border-radius: 0;
}

@media(max-width: 768px){
    /* subscribe section  */

.subscribe__section{
    position: relative;
    background-color: #5B5B5B;
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}



.subscribe__section .subscribe__flower{
    width: 10rem;
}

.subscribe__section #bottomLeftFlower{

    transform: translateX(-55%) translateY(2vh);

}

.subscribe__section .subscribe__container{
    width: 80%;
    margin-top: 2rem;
   
}

.subscribe__section .subscribe__container .subscribe__title{
    font-size: 25px;

}

.subscribe__section .subscribe__container .subscribe__description{

    font-size: 22px;
    padding: 0 ;
    text-align: end;
}

.subscribe__section .subscribe__container .subscribe__form{
    text-align: end;
    margin-top: 1rem;
    width: 85%;
    margin-bottom: 2rem;
}

.subscribe__section .subscribe__container .subscribe__form form {
    flex-direction: column;
    height: 8rem;
    justify-content: space-between;
}

.subscribe__section .subscribe__container .subscribe__form form input[type="email"]{
    border: 1.5px #EFB7B5 solid;
    font-size: 20px;
    padding: .3rem 2rem;
    align-self: center;
    width: 100%;
}

.subscribe__section .subscribe__container .subscribe__form form input[type="submit"]{

    font-size: 25px;
    padding: .5rem 0rem;
    max-width: 150px;
    min-width: 150px;
    align-self: center;
}

}

@media(max-width: 500px){
    .subscribe__section .subscribe__container .subscribe__title{
        font-size: 20px;
    
    }
    
    
    .subscribe__section .subscribe__container .subscribe__description{
    
        font-size: 18px;
    }
    
    .subscribe__section .subscribe__container .subscribe__form{
    
        width: 80%;
    
    }
    
    
    .subscribe__section .subscribe__container .subscribe__form form input[type="email"]{
        font-size: 18px;
        padding: .3rem 2rem;
    }

    
    .subscribe__section .subscribe__container .subscribe__form form input[type="submit"]{

        font-size: 22px;
     
    }
    
    
}

@media(max-width: 300px){
    .subscribe__section .subscribe__container .subscribe__title{
        font-size: 17px;
        text-align: center;
    }
    
    .subscribe__section .subscribe__flower{
        width: 8rem;
    }
    
    
    .subscribe__section .subscribe__container .subscribe__description{
    
        font-size: 16px;
    }
    
    .subscribe__section .subscribe__container .subscribe__form{
    
        width: 70%;
    
    }
    
    
    .subscribe__section .subscribe__container .subscribe__form form input[type="email"]{
        font-size: 16px;
        padding: .3rem 2rem;
    }
    
    
    .subscribe__section .subscribe__container .subscribe__form form input[type="submit"]{
    
        font-size: 20px;
     
    }
}