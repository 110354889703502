@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@600&display=swap");

.login-page {
  font-family: "El Messiri", sans-serif;
}

/* .overview-sl {
  background-image: url("/public/images/home_picture.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */
