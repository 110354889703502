.course__overview {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.course__overview .course__title__section {
  position: relative;
  max-height: 20vw;
  min-height: 20vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0.5vw #efb7b5 solid;
}

.course__overview .course__title__section > img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
}

.course__overview .course__title__section > .course__title__overlay {
  background-color: #a0d1cc;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  opacity: 0.6;
  z-index: -1;
}

.course__overview__section {
  display: flex;
  flex-direction: row;
  width: 90%;
  align-self: center;
  margin: 4vw 0;
}

.course__overview__section .course__leftside {
  flex: 1 1 30%;
  min-width: 30%;
}

.course__overview__section .course__leftside .course__video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1vw;
  overflow: hidden;
}

.course__overview__section .course__leftside .course__video .video {
  max-width: 100%;
  min-width: 100%;
  z-index: -1;
}

.course__overview__section .course__leftside .course__video .play {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 5vw;
}

.course__overview__section .course__leftside .course__items {
  background-color: #fff;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vw;
}

.course__overview__section .course__leftside .course__items ul {
  list-style: none;
  min-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 0;
}

.course__overview__section .course__leftside .course__items ul li {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin: auto;
  min-width: 100%;
  padding: 1vw 0;
  border-bottom: 1px #5b5b5b55 solid;
}

.course__overview__section .course__leftside .course__items ul li:last-child {
  border-bottom: none;
}

.course__overview__section .course__leftside .course__items ul li .content {
  text-align: start !important;
  margin: 0;
  padding: 0;
}

.course__overview__section .course__leftside .course__items ul li > * {
  flex: 0.5;
  margin: 0;
  padding: 0;
}

.course__overview__section .course__leftside .course__items ul li > div {
  display: flex;
  flex-direction: row-reverse;
}

.course__overview__section .course__leftside .course__items ul li img {
  max-width: 1.5vw;
  margin-left: 1vw;
}

.course__overview__section
  .course__leftside
  .course__items
  .course__subscribe__btn {
  background-color: #efb7b5;
  border: none;
  outline: none;
  padding: 0.3vw 1vw;
  border-radius: 1vw;
  margin-bottom: 2vw;
}

.course__overview__section
  .course__leftside
  .course__items
  .course__subscribe__btn
  .title {
  margin: 0;
  padding: 0;
}

.course__overview__section .course__rightside {
  flex: 1 1 70%;
  background-color: #fff;
  padding: 2vw 3vw;
  margin-left: 2vw;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.course__overview__section .course__rightside .title,
.course__overview__section .course__rightside .content {
  margin: 0;
  padding: 0;
}

.course__overview__section .course__rightside .course__overview__item {
  margin-bottom: 2vw;
  align-self: flex-end;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 98%;
  align-self: center;
}

.course__overview__section
  .course__rightside
  .course__overview__item
  .course__program__container {
  display: flex;
  flex-direction: column;
  user-select: none;
  margin-top: 1vw;
}

.course__overview__section
  .course__rightside
  .course__overview__item
  .course__program__container
  .course__title {
}
.course__overview__section
  .course__rightside
  .course__overview__item
  .course__program__container
  .course__title
  .course__title__container {
  background-color: #5b5b5b;
  border: 0.15vw #efb7b5 solid;
  border-radius: 0.4vw;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0.5vw 2vw;
  cursor: pointer;
}

.course__overview__section
  .course__rightside
  .course__overview__item
  .course__program__container
  .course__title
  .course__title__container
  i {
  color: #fff;
  font-size: 1.8vw;
  margin-left: 2vw;
}

.course__overview__section
  .course__rightside
  .course__overview__item
  .course__program__container
  .active__course__title
  .course__title__container
  i {
  transform: rotate(-90deg);
}

.course__overview__section
  .course__rightside
  .course__overview__item
  .course__program__container
  .course__title
  .course__items__container {
  margin-right: 2vw;
  display: none;
}

.course__overview__section
  .course__rightside
  .course__overview__item
  .course__program__container
  .active__course__title
  .course__items__container {
  display: block;
}

.course__overview__section
  .course__rightside
  .course__overview__item
  .course__program__container
  .course__subtitle {
  margin: 0.5vw 0;
}

@media (max-width: 768px) {
  .course__overview .course__title__section {
    max-height: 40vw;
    min-height: 40vw;

    border-top: 5px #efb7b5 solid;
  }

  .course__overview__section {
    flex-direction: column;
    align-self: center;
    align-items: center;
    margin: 2rem 0;
  }

  .course__overview__section .course__leftside {
    flex: 1;
    min-width: 100%;
  }

  .course__overview__section .course__leftside .course__video {
    margin-top: 2rem;
    border-radius: 1rem;
  }

  .course__overview__section .course__leftside .course__video .play {
    max-width: 3rem;
    min-width: 3rem;
  }

  .course__overview__section .course__leftside .course__items {
    border-radius: 1rem;

    margin-top: 2rem;
  }

  .course__overview__section .course__leftside .course__items ul li {
    padding: 1rem 0;
  }

  .course__overview__section .course__leftside .course__items ul li img {
    max-width: 1.2rem;
    margin-left: 1.5rem;
  }

  .course__overview__section
    .course__leftside
    .course__items
    .course__subscribe__btn {
    padding: 0.3rem 1rem;
    border-radius: 1rem;
    margin-bottom: 2rem;
  }

  .course__overview__section .course__rightside {
    flex: 1;
    padding: 1rem 1rem;
    margin-left: 0;
    border-radius: 1rem;
    margin-top: 3rem;
  }

  .course__overview__section .course__rightside .course__overview__item {
    margin-bottom: 2rem;
  }

  .course__overview__section
    .course__rightside
    .course__overview__item
    .course__program__container {
    margin-top: 1rem;
  }

  .course__overview__section
    .course__rightside
    .course__overview__item
    .course__program__container
    .course__title
    .course__title__container {
    border-radius: 0.3rem;
    padding: 0.2rem 1rem;
  }

  .course__overview__section
    .course__rightside
    .course__overview__item
    .course__program__container
    .course__title
    .course__title__container
    i {
    font-size: 1.5rem;
    margin-left: 1.5rem;
  }

  .course__overview__section
    .course__rightside
    .course__overview__item
    .course__program__container
    .course__title
    .course__items__container {
    margin-right: 2rem;
  }

  .course__overview__section
    .course__rightside
    .course__overview__item
    .course__program__container
    .course__subtitle {
    margin: 0.5rem 0;
  }
}

@media (max-width: 500px) {
  .course__overview .course__title__section {
    max-height: 50vw;
    min-height: 50vw;
  }

  .course__overview__section {
    margin: 0;
  }

  .course__overview__section .course__leftside .course__video {
    margin-top: 1.5rem;
    border-radius: 0.5rem;
  }

  .course__overview__section .course__leftside .course__items {
    border-radius: 0.5rem;

    margin-top: 1.5rem;
  }

  .course__overview__section .course__leftside .course__items ul li img {
    max-width: 1rem;
  }

  .course__overview__section
    .course__leftside
    .course__items
    .course__subscribe__btn {
    padding: 0.2rem 0.8rem;
    border-radius: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .course__overview__section .course__rightside {
    padding: 0.8rem;
    border-radius: 0.5rem;
    margin-top: 1.5rem;
  }

  .course__overview__section .course__rightside .course__overview__item {
    margin-bottom: 1.5rem;
  }

  .course__overview__section
    .course__rightside
    .course__overview__item
    .course__program__container
    .course__title
    .course__title__container {
    border-radius: 0.2rem;
    padding: 0.1rem 0.8rem;
  }

  .course__overview__section
    .course__rightside
    .course__overview__item
    .course__program__container
    .course__title
    .course__title__container
    i {
    font-size: 1.2rem;
    margin-left: 1.2rem;
  }

  .course__overview__section
    .course__rightside
    .course__overview__item
    .course__program__container
    .course__title
    .course__items__container {
    margin-right: 1.5rem;
  }

  .course__overview__section
    .course__rightside
    .course__overview__item
    .course__program__container
    .course__subtitle {
    margin: 0.2rem 0;
  }
}

@media (max-width: 300px) {
  .contact .contact__section .contact__leftside form .content {
    font-size: 10px;
  }
}
