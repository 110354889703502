.subscribe__content{
    flex: .5;
}

.subscribe__content{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    text-align: end;
    padding: 1.5rem;
}



.subscribe__content .subscribe__form{
    text-align: end;
}

.subscribe__content .subscribe__form form {
    display: flex;
    flex-direction: row-reverse;
    height: 3.5vw;
}


.subscribe__content .subscribe__form form input[type="email"]{
    background: none;
    outline: none;
    background-color: #5B5B5B;
    border: 1px #EFB7B5 solid;
    color: #C4C4C4;
    font-family: 'El Messiri', sans-serif;
    font-size: 1.4vw;
    padding: 0 1vw;
    border-radius: 0;
}

.subscribe__content .subscribe__form form input[type="submit"]{
    background-color: #EFB7B5;
    border: none;
    outline: none;
    color: #ffffff;
    font-family: 'Arslan-Wessam-B', sans-serif;
    font-size: 1.6vw;
    padding: 0 1.3vw;
    border-radius: 0;
}


@media(max-width: 768px){
    .subscribe__content{
        flex: auto;
        max-height: 50%;
        max-height: 50%;
    }

    .subscribe__content{

        padding: .8rem;
    }
    


    
    .subscribe__content .subscribe__form{
        text-align: end;
        margin-top: 0rem;
        width: 80%;
        margin-bottom: 0rem;
        align-self: center;
        margin: auto;
    }
    
    .subscribe__content .subscribe__form form {
        flex-direction: column;
        height: 6rem;
        align-items: center;
    
        justify-content: space-between;
    }
    
    .subscribe__content .subscribe__form form input[type="email"]{
        border: 1.5px #EFB7B5 solid;
        font-size: 18px;
        padding: .3rem 1rem;
        align-self: center;
        width: 100%;
    }
    
    .subscribe__content .subscribe__form form input[type="submit"]{
    
        font-size: 20px;
        padding: .5rem 0rem;
        max-width: 120px;
        min-width: 120px;
        align-self: center;
        margin: 0;
    }
}