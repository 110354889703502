.readmore__btn{
    font-family: 'El Messiri', sans-serif;
    color: #EFB7B5;
    font-size: 1.5vw;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.readmore__btn .fa{
    margin-right: 1vw;
    transition: all .3s ease;
}

.readmore__btn:hover .fa{
    transform: translateX(-3px);
}




@media(max-width: 768px){

    .readmore__btn{
        font-size: 22px;
    }
}

.readmore__btn .fa{
    margin-right: 15px;
}


@media(max-width: 500px){

    .readmore__btn{
        font-size: 18px;
    }
}

@media(max-width: 300px){

    .readmore__btn{
        font-size: 16px;
    }
}
