/* Home page first section */

.home__page .first__section {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 35vw;
}

.home__page .first__section .home__img img {
  width: 100%;
  z-index: 0;
  /* top: -10vw; */
  position: absolute;
}

.home__page .first__section .subscribe__box {
  z-index: 1;
  position: relative;
  border: 2px #efb7b5 solid;
  width: 35%;
  /* min-width: 25rem;
    max-width: 25rem; */
  justify-self: center;
  margin: 9% auto;
  margin-left: 5rem;
  background-color: rgba(91, 91, 91, 0.72);
  min-height: 20vh;
  height: 70%;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 2rem 0rem;
}

.home__page .first__section .subscribe__box .subscribe__cloud {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transform: translateX(-30%) translateY(-20%);
}

.home__page .first__section .subscribe__box .subscribe__cloud img {
  z-index: 0;
}

.home__page .first__section .subscribe__box .subscribe__title {
  z-index: 1;
  font-family: "Lexend Mega", sans-serif;
  font-size: 1.5vw;
}

.home__page .first__section .subscribe__box .subscribe__description {
  font-family: "Lalezar", cursive;
  text-align: center;
  font-size: 2vw;
}

.home__page .first__section .subscribe__box .subscribe__button {
  padding: 0.5rem 1.5rem;
  border: none;
  outline: none;
  background-color: #efb7b5;
  color: #ffffff;
  font-family: "Lalezar", cursive;
  border-radius: 0.25vw;
  cursor: pointer;
  font-size: 1.7vw;
}

.home__page .first__section .subscribe__box .subscribe__button:hover {
  background-color: #efb7b5ee;
}

@media (max-width: 1050px) {
  .home__page .first__section .subscribe__box {
    max-height: 26vh;
  }

  .home__page .first__section .home__img img {
    top: -0vh;
  }
}

@media (max-width: 768px) {
  /* Home page first section */
  .home__page .first__section {
    overflow: hidden;
    min-height: 90vh;
  }

  .home__page .first__section .home__img img {
    width: auto;
    height: 100%;
    top: 0;
    /* right: -140px; */
  }

  .home__page .first__section .subscribe__box {
    border: none;
    width: 80%;
    margin: 20vh auto;
    background: none;
    min-height: 50vh;
  }
  /* .home__page .first__section .subscribe__box .subscribe__cloud {
    display: none;
  } */

  .home__page .first__section .subscribe__box .subscribe__title {
    z-index: 1;
    width: 80%;
    align-self: flex-start;
    font-size: 30px;
    text-align: start;
  }

  .home__page .first__section .subscribe__box .subscribe__description {
    text-align: end;
    font-size: 30px;
    width: 90%;
    align-self: flex-end;
  }

  .home__page .first__section .subscribe__box .subscribe__button {
    padding: 0.5rem 2rem;

    border-radius: 5px;
    font-size: 22px;
    align-self: flex-end;
  }
}

@media (max-width: 500px) {
  .home__page .first__section {
    overflow: hidden;
    min-height: 10vh;
  }

  .home__page .first__section .home__img img {
    /* width: auto; */
    /* width: min-content; */
    object-fit: cover;
    height: 80%;
    top: 0;
    /* right: -140px; */
  }

  .home__page .first__section .subscribe__box .subscribe__title {
    font-size: 16px;
    /* display: none; */
  }

  .home__page .first__section .subscribe__box .subscribe__description {
    font-size: 14px;
  }

  .home__page .first__section .subscribe__box .subscribe__button {
    padding: 0.3rem 1.5rem;

    border-radius: 5px;
    font-size: 12px;
    align-self: flex-end;
  }
}

@media (max-width: 300px) {
  .home__page .first__section {
    min-height: 50vh;
  }

  .home__page .first__section .subscribe__box .subscribe__title {
    font-size: 16px;
  }

  .home__page .first__section .subscribe__box .subscribe__description {
    font-size: 13px;
  }

  .home__page .first__section .subscribe__box .subscribe__button {
    padding: 0.3rem 1.5rem;

    border-radius: 5px;
    font-size: 18px;
    align-self: flex-end;
  }
}
