.header {
  height: 12vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 3vw;
  justify-items: center;
  position: relative;
}

.header * {
  user-select: none;
}

.header .open {
  font-size: 1.5vw;
  justify-self: flex-start !important;
  background-color: #efb7b5;
  position: absolute;
  top: 2vh;
  z-index: 2;
  width: 20vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #ffffff;
}

.header .open .menu__content {
  font-family: "El Messiri", sans-serif;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  margin-top: 0;
  transform: translateY(-3vh);
  padding: 0;
}

.header .open .menu__content ul {
  list-style: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0;
  width: 100%;
  margin: 0.5vw 0;
}
.header .open .menu__content ul li {
  font-size: 1.4vw;
  margin: 0.1vw 0px;
  width: 100%;
  padding: 0.5vw;
  cursor: pointer;
}

#work__with__me {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: 0;
  margin: auto;
  padding: 0;
  position: relative;
}

#work__with__me > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#work__with__me ul {
  position: absolute;
  background-color: #efb7b5;
  top: -1vw;
  right: -100%;
  display: none;
}

#work__with__me:hover ul {
  display: block;
}

#work__with__me .fa {
  margin: 0;
  padding: 0;
  margin-left: 1vw;
}

.header .open .menu__content .menu__bar__buttons {
  display: flex;
  flex-direction: row;
}

.header .open .menu__content .menu__bar__buttons button {
  background-color: #5b5b5b;
  color: #fff;
  border: none;
  outline: none;
  padding: 0.3vw 1vw;
  font-size: 1vw;
  font-family: "El Messiri", sans-serif;
  border-radius: 0.2vw;
  margin: auto 0.3vw;

  cursor: pointer;
}

.header .open .menu__content .socialmedia__icons {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-top: 2vh;
  color: #5b5b5b;
}

.header .open .menu__content .socialmedia__icons img {
  width: 1.5vw;
  height: 1.5vw;
  cursor: pointer;
}

.header .menu__bar .fa,
.header .open .fa {
  margin: 0;
  padding: 1vw;
  font-size: 2vw;
  cursor: pointer;
  align-self: flex-start;
}

/* .header .menu__bar .fa{
    font-size: 2vw;
    padding: 1.6vw;
} */

.header .menu__bar > .fa:hover,
.header .open > .fa:hover {
  opacity: 0.8;
}

.header .menu__bar {
  justify-self: flex-start !important;
  background: none;
  position: absolute;
  top: 2vh;
  z-index: 2;
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #5b5b5b;
}

.header .menu__bar .menu__content {
  display: none;
}

.header .logo {
  justify-self: center;
  margin: auto;
  cursor: pointer;
}

.header .logo img {
  max-height: 8vh;
  min-height: 8vh;
}

@media (max-width: 1050px) {
  .header .menu__bar {
    position: absolute;
  }

  .header .menu__bar .fa {
    padding: 1rem;
    font-size: 20px;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .header .open {
    font-size: 20px;
    width: 100%;
    top: 0;
    left: 0;
    padding-bottom: 1rem;
  }

  .header .open .menu__content {
    transform: translateY(0);
  }

  .header .open .fa {
    margin-top: 2rem;
    margin-left: 2rem;
    font-size: 20px;
  }

  .header .open .menu__content ul {
    margin: 10px 0;
  }

  .header .open .menu__content ul li {
    font-size: 23px;
    margin: 2px 0px;
    padding: 8px;
  }

  .header .open .menu__content .menu__bar__buttons button {
    padding: 3px 20px;
    font-size: 20px;
    border-radius: 5px;
    margin: auto 10px;
  }

  .header .open .menu__content .socialmedia__icons {
    margin-top: 20px;
  }

  .header .open .menu__content .socialmedia__icons img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  #work__with__me {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #work__with__me div > .fa {
    font-size: 20px;
    align-self: center;
    margin-left: 20px;
  }

  #work__with__me:hover div > .fa {
    transform: rotate(90deg);
  }

  #work__with__me ul {
    position: static;
    background-color: #efb7b5;
    top: 0;
    right: -100%;
    display: none;
  }
}

@media (max-width: 500px) {
  .header .logo img {
    max-height: 3rem;
    min-height: 3rem;
  }

  .header .open .menu__content ul li {
    font-size: 21px;
  }

  .header .open .menu__content .menu__bar__buttons button {
    padding: 3px 16px;
    font-size: 16px;
    border-radius: 5px;
    margin: auto 6px;
  }
}
