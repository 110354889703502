@tailwind base;
@tailwind components;

@import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lalezar&family=Lexend+Mega&display=swap");

@tailwind utilities;

@layer utilities {
  @variants responsive {
    .h-60 {
      height: 60vh;
    }
    .h-80 {
      height: 80vh;
    }
    .font-arabic {
      font-family: "Lalezar", sans-serif;
    }

    .min-h-80 {
      min-height: 80vh;
    }

    .h-90 {
      height: 90vh;
    }

    .h-100 {
      height: 100vh;
    }

    .h-125 {
      height: 125vh;
    }

    .h-150 {
      height: 150vh;
    }

    .w-90 {
      width: 90vh;
    }

    .w-100 {
      width: 100vh;
    }

    .w-125 {
      width: 125vh;
    }

    .w-150 {
      width: 150vh;
    }

    .h-new-48 {
      height: 48vh;
    }

    .min-h-200 {
      min-height: 200px;
    }
    .width-60-100 {
      width: 60%;
    }
    .width-70-100 {
      width: 70%;
    }
    .width-90-100 {
      width: 90%;
    }
    .gap-2 {
      gap: 2%;
    }
    .min-w-200 {
      min-width: 200px;
    }

    .min-w-600 {
      min-width: 600px;
    }
    .max-width-50-100 {
      max-width: 50%;
    }
    .flex-70 {
      flex: 1 1 70%;
    }
    .flex-45 {
      flex: 1 1 45%;
    }
    .flex-25 {
      flex: 1 1 25%;
    }
    .flex-30 {
      flex: 1 1 30%;
    }

    .border-bottom-grey {
      border-bottom: 1px solid #d8d8d8;
    }
    .border-bottom {
      border-bottom: 1px solid;
    }
    .text-gradient {
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .h-about-pics {
      height: 450px;
    }

    .w-about-pics {
      width: 450px;
    }

    .h-about-pics-xl {
      height: 650px;
    }

    .w-about-pics-xl {
      width: 650px;
    }

    .bg-reghda-pink {
      background-color: #e7bdcb;
    }

    .text-reghda-pink {
      color: #e7bdcb;
    }

    .bg-mervet-pink {
      background-color: #efb7b5;
    }

    .text-mervet-pink {
      color: #efb7b5;
    }

    .bg-reghda-black {
      background-color: #5c5c5c;
    }

    .text-reghda-black {
      color: #5c5c5c;
    }

    .border-reghda-black {
      border-color: #5c5c5c;
    }

    .bg-reghda-light-blue {
      background-color: #fbf3f1;
    }

    .bg-reghda-blue {
      background-color: #efb7b6;
    }

    .text-reghda-light-blue {
      color: #fbf3f1;
    }

    .text-reghda-blue {
      color: #efb7b6;
    }

    .border-reghda-blue {
      border-color: #efb7b6;
    }

    .bg-reghda-black {
      background-color: #5c5c5c;
    }

    .text-reghda-black {
      color: #5c5c5c;
    }

    .border-reghda-black {
      border-color: #5c5c5c;
    }

    .border-1 {
      border-width: 1px;
    }
    .border-bottom-1 {
      border-bottom: 1px solid;
    }

    .text-xxs {
      font-size: 0.5rem;
      line-height: 0.75rem;
    }
    .left-45 {
      left: 45%;
    }

    .width-60 {
      width: 60%;
    }

    .border-1 {
      border-width: 1px;
    }

    .text-xxs {
      font-size: 0.5rem;
      line-height: 0.75rem;
    }
    .text-tiny {
      font-size: 0.25rem;
      line-height: 0.5rem;
    }

    .leading-raghda {
      line-height: 10;
    }

    .tracking-raghda {
      letter-spacing: 0.5em;
    }

    .text-8xl {
      font-size: 4rem;
    }
    .rtl {
      direction: rtl;
    }
  }
}
