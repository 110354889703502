/* services */

.home__page .services__section{
    margin-top: 1vw;
}

.home__page .services__section .services__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    align-self: center;
    margin: auto;
}

.home__page .services__section .services__container .services__title{
    font-family: 'Lalezar', cursive;
    font-size: 2.5vw;
    color: #5B5B5B;

}


.home__page .services__section .services__container .services__description{
    font-family: 'El Messiri', sans-serif;
    font-size: 1.8vw;
    text-align: center;
    width: 90%;
    color: #EFB7B5;
}


.home__page .services__section .services__container .services__images{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;
    flex-wrap: wrap;
    z-index: 1;
}



@media(max-width: 768px){
    /* services */

.home__page .services__section{
    margin-top: 0vh;
}

.home__page .services__section .services__container .services__title{
    font-size: 28px;

}

.home__page .services__section .services__container .services__description{
    font-size: 22px;
    width: 100%;
}

.home__page .services__section .services__container .services__images{
    flex-direction: column;
    width: auto;
    align-items: center;
    margin-top: 1rem;
    flex-wrap: wrap;
    z-index: 1;
}



}


@media(max-width: 500px){
    .home__page .services__section .services__container .services__title{
        font-size: 22px;
    }
    
    .home__page .services__section .services__container .services__description{
        font-size: 18px;
        width: 100%;
    }
    
    
    
}


@media(max-width: 300px){
    .home__page .services__section .services__container .services__title{
        font-size: 20px;
    }
    
    .home__page .services__section .services__container .services__description{
        font-size: 17px;
        width: 100%;
    }
    
   
}