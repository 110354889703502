 .service{
    position: relative;
    transition: all .3s ease;
    cursor: pointer;
}

 .service:hover{
    transform: scale(1.007);
    box-shadow: 0px 0px .5vw #00000044;
}

 .service img{
    min-width: 100%;
    height: 25vw;
}

 .service .service__type{
    position: absolute;
    bottom: 1.5vw;
    right: 0;
    background-color: #A0D1CC;
    color: #FFFFFF;
    font-family: 'Lexend Mega', sans-serif;
    max-width: 10vw;
    font-size: 1.5vw;
    text-align: center;
    word-wrap: break-word;
    padding: 1vw;
}

@media(max-width: 768px){
     .service img{
        width: 100%;
        height: auto;
    }
    
     .service .service__type{
        bottom: 3vh;
        max-width: 150px;
        font-size: 20px;
        padding: 1vw;
    }
    
     .service{
        margin-bottom: 2rem;
    }
}

@media(max-width: 500px){
     .service img{
        width: 70vw;
        height: auto;
    }
    
     .service .service__type{
        bottom: 1.5rem;
        max-width: 120px;
        font-size: 16px;
        padding: 1vw;
    }
}

@media(max-width: 300px){
     .service img{
        width: 60vw;
        height: auto;
    }
    
     .service .service__type{
        bottom: 1.5rem;
        max-width: 110px;
        font-size: 15px;
        padding: 1vw;
    }
}