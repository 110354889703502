.groupcoaching {
  display: flex;
  flex-direction: column;
}

.groupcoaching__intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vw;
}

.groupcoaching__intro .groupcoaching__header {
  display: flex;
  flex-direction: row;
  border: 1px #5b5b5baa solid;
  margin: auto 2vw;
  border-left: none;
  border-right: none;
}

.groupcoaching__header .groupcoaching__header__leftside,
.groupcoaching__header .groupcoaching__header__rightside {
  flex: 0.5;
  max-width: 50%;
  margin: 2vw;
}

.groupcoaching__header .groupcoaching__header__leftside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px #5b5b5baa solid;
  margin: 0;
  padding: 0;
}
.groupcoaching__header .groupcoaching__header__leftside > * {
  text-align: center !important;
  margin: auto 5vw;
}

.groupcoaching__header .groupcoaching__header__rightside {
  display: flex;
  flex-direction: row;
}

.groupcoaching__header .groupcoaching__header__rightside .header__img {
  flex: 0.5;
  height: 18vw;
  width: 18vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin: 1vw;
}

.groupcoaching__header .groupcoaching__header__rightside .header__img img {
  max-width: 100%;
  min-width: 100%;
}

.groupcoaching__intro > .content {
  text-align: center !important;
  margin: 5vw 2vw;
}

.program__definition {
  background-color: #efb7b5;
  min-height: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.program__definition .content {
  color: #ffff !important;
  margin: auto 15vw;
  text-align: center !important;
}

.program__schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.schedule__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 5vw 4vw;
  margin-top: 7vw;
}

.schedule__container .schedule__item {
  background-color: #5b5b5b;
  border-radius: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0.5vw;
  max-width: 25vw;
  min-width: 25vw;
  position: relative;
}

.schedule__container .schedule__item .content {
  text-align: center !important;
}

.schedule__container .schedule__item .schedule__id {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 8vw;
  margin: 0;
  padding: 0;
  font-style: italic;
  font-weight: 700;
  transform: translateY(-5vw) translateX(1vw);
  color: #efb7b5;
  opacity: 0.7;
}

@media (max-width: 768px) {
  .groupcoaching__intro .groupcoaching__header {
    flex-direction: column;
    margin: auto 1rem;
    border-left: none;
    border-right: none;
  }

  .groupcoaching__header .groupcoaching__header__leftside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: none;
    border-bottom: 1px #5b5b5baa solid;
    margin: 0;
    padding: 0;
  }

  .groupcoaching__header .groupcoaching__header__leftside,
  .groupcoaching__header .groupcoaching__header__rightside {
    flex: 1;
    max-width: 100%;
    margin: 1rem;
  }

  .groupcoaching__header .groupcoaching__header__rightside {
    flex-direction: column;
    align-items: center;
  }

  .groupcoaching__header .groupcoaching__header__leftside > * {
    margin: 1rem;
  }

  .groupcoaching__header .groupcoaching__header__rightside .header__img {
    flex: 0.5;
    height: 60vw;
    max-height: 60vw;

    width: 70vw;
    margin: 1rem;
  }

  .groupcoaching__intro > .content {
    text-align: end !important;
    margin: 2rem 1rem;
  }

  .program__definition .content {
    margin: 1rem 1rem;
  }

  .schedule__container {
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem 1rem;
    margin-top: 3rem;
  }

  .schedule__container .schedule__item {
    border-radius: 0.6rem;
    padding: 0.5rem;
    max-width: 40vw;
  }

  .schedule__container .schedule__item .schedule__id {
    font-size: 4rem;
    transform: translateY(-2.5rem) translateX(0.5rem);
  }
}

@media (max-width: 500px) {
  .schedule__container {
    grid-template-columns: 1fr;
    grid-gap: 2rem 1rem;
    margin-top: 3rem;
  }

  .schedule__container .schedule__item {
    border-radius: 0.6rem;
    padding: 0.5rem;
    max-width: 80vw;
  }

  .schedule__container .schedule__item .schedule__id {
    font-size: 3.5rem;
    transform: translateY(-2.3rem) translateX(0.5rem);
  }
}
