.contact{
    display: flex;
    flex-direction: column;
}

.contact .contact__section{
    display: flex;
    flex-direction: row;
    width: 90%;
    align-self: center;
}

.contact .contact__section .contact__leftside,
.contact .contact__section .contact__rightside{
    flex: .5;
    max-width: 50%;
    padding: 5vw ;
    display: flex;
    flex-direction: column;
    align-items: center;
}



.contact .contact__section .contact__rightside  img{
    max-width: 75%;
    margin-bottom: 2vw;
}

.contact .contact__section .contact__rightside .title,
.contact .contact__section .contact__rightside .content{
    margin: 0;
    padding: 0;
    text-align: center !important;
    margin-bottom: 2vw;
}


.contact .contact__section .contact__rightside .socialmedia__icons{
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-around;
}

.contact .contact__section .contact__rightside .socialmedia__icons img{
    max-width: 1.8vw;
    min-width: 1.8vw;
}

.contact .contact__section .contact__leftside{
    padding-left: 10vw;
    padding-right: 0;
}

.contact .contact__section .contact__leftside form{
    width: 100%;
    padding-top: 0;
}

.contact .contact__section .contact__leftside form .field{
    display: flex;
    flex-direction: row-reverse;
    min-width: 100%;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 3vw;
}

.contact .contact__section .contact__leftside form  .title,
.contact .contact__section .contact__leftside form  .content{
    margin: 0;
    padding: 0;
}

.contact .contact__section .contact__leftside form .field input,
.contact .contact__section .contact__leftside form .field textarea{
    min-height: 2.5vw;
    min-width: 60%;
    max-width: 60%;
    background-color: #EFB7B5;
    border: none;
    outline: none;
    padding: .3vw 1vw;
    color: #5B5B5B;
    font-family: 'El Messiri', sans-serif;
    font-size: 1.2vw;
    text-align: end;
}

.contact .contact__section .contact__leftside form .field textarea{
    max-lines: 10;
    min-height: 10vw;
    max-height: 10vw;
}


.contact .contact__section .contact__leftside form .contact__service__type{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 1vw;
}

.contact .contact__section .contact__leftside form .contact__service__type .content{
    font-size: 1.4vw;
}

.contact .contact__section .contact__leftside form .contact__service__type .service__types{
    align-self: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.contact .contact__section .contact__leftside form .contact__service__type .service__types .field{
    align-self: flex-start;
    align-items: center;
    margin-bottom: 1vw;
    min-width: 50%;
    justify-content: flex-end;
    
}

.contact .contact__section .contact__leftside form .contact__service__type .service__types .field input{
    min-width: 1.5vw;
    max-width: auto;
    align-self: flex-start;
    margin-right: 2vw;
    
}

.contact .contact__section .contact__leftside form .contact__service__type .service__types .field input[type="checkbox"]::after{
    min-width: 1.5vw;
    max-width: auto;
    align-self: flex-start;
    margin-right: 2vw;
    border: none;
    outline: none;

}

.contact .contact__section .contact__leftside form .field input[type="submit"]{
    align-self: flex-start;
    justify-self: flex-start;
    margin-right: auto;
    max-width: 8vw;
    min-width: 8vw;
    width: 2vw;
    text-align: center;
    font-family: 'Lalezar', cursive;
    color: #fff;
    font-size: 1.5vw;
    
    
}




@media(max-width: 768px){
    
.contact .contact__section{
    flex-direction: column-reverse;
    width: 95%;
    
}

.contact .contact__section .contact__leftside,
.contact .contact__section .contact__rightside{
    flex: .5;
    max-width: 100%;
    padding: 2rem ;
}

.contact .contact__section .contact__rightside  img{
    margin-bottom: 1rem;
}

.contact .contact__section .contact__rightside .title,
.contact .contact__section .contact__rightside .content{

    margin-bottom: 1rem;
}

.contact .contact__section .contact__rightside .socialmedia__icons{
    margin-top: 1rem;
}

.contact .contact__section .contact__rightside .socialmedia__icons img{
    max-width: 1.8rem;
    min-width: 1.8rem;
}

.contact .contact__section .contact__leftside{
    padding-left: 2rem;
    padding-right: 2rem;
}

.contact .contact__section .contact__leftside form .field{

    margin-bottom: 2rem;
}

.contact .contact__section .contact__leftside form .field input,
.contact .contact__section .contact__leftside form .field textarea{
    min-height: 2rem;
    min-width: 70%;
    max-width: 70%;
    padding: .3rem 1rem;
    font-size: 1.2rem;
}

.contact .contact__section .contact__leftside form .field textarea{
    min-height: 8rem;
    max-height: 8rem;
}

.contact .contact__section .contact__leftside form .contact__service__type{
    margin-bottom: 1rem;
}

.contact .contact__section .contact__leftside form .contact__service__type .content{
    font-size: 1.2rem;
}

.contact .contact__section .contact__leftside form .contact__service__type .service__types .field{

    margin-bottom: .5rem;

}

.contact .contact__section .contact__leftside form .contact__service__type .service__types .field input{
    min-width: 1rem;

    margin-right: 1rem;
    
}


.contact .contact__section .contact__leftside form .field input[type="submit"]{
    max-width: 8rem;
    min-width: 8rem;
    font-size: 1.5rem;
    
    
}

}

@media(max-width: 500px){

    .contact .contact__section{
        flex-direction: column-reverse;
        width: 100%;
        
    }

    .contact .contact__section .contact__leftside,
.contact .contact__section .contact__rightside{
    flex: .5;
    max-width: 100%;
    padding: 1.5rem 1rem ;
}

.contact .contact__section .contact__rightside .socialmedia__icons img{
    max-width: 1.5rem;
    min-width: 1.5rem;
}

.contact .contact__section .contact__leftside{
    padding-left: 1rem;
    padding-right: 1rem;
}

.contact .contact__section .contact__leftside form .field{

    margin-bottom: 1.5rem;
}

.contact .contact__section .contact__leftside form  .title,
.contact .contact__section .contact__leftside form  .content{
    font-size: 1rem;
}


.contact .contact__section .contact__leftside form .field input,
.contact .contact__section .contact__leftside form .field textarea{
    min-height: 2rem;
    min-width: 65%;
    max-width: 65%;
    padding: .2rem .8rem;
    font-size: 1rem;
}

.contact .contact__section .contact__leftside form .field textarea{
    min-height: 7rem;
    max-height: 7rem;
}

.contact .contact__section .contact__leftside form .contact__service__type{
    margin-bottom: .8rem;
}

.contact .contact__section .contact__leftside form .contact__service__type .content{
    font-size: 1rem;
}

.contact .contact__section .contact__leftside form .contact__service__type .service__types .field{

    margin-bottom: .0rem;

}

.contact .contact__section .contact__leftside form .contact__service__type .service__types .field input{
    min-width: .8rem;

    margin-right: 1rem;
    
}

.contact .contact__section .contact__leftside form .field input[type="submit"]{
    max-width: 6rem;
    min-width: 6rem;
    font-size: 1.2rem;
    
    
}








}