.content {
  font-family: "El Messiri", sans-serif;
  font-size: 1.6vw;
  color: #5b5b5b;
}

.content__big {
  font-size: 2vw;
}

@media (max-width: 768px) {
  .content {
    font-size: 1.4rem;
  }

  .content__big {
    font-size: 1.6rem;
  }
}

@media (max-width: 500px) {
  .content {
    font-size: 1.1rem;
  }

  .content__big {
    font-size: 1.4rem;
  }
}

@media (max-width: 300px) {
  .content {
    font-size: 0.9rem;
  }

  .content__big {
    font-size: 1.2rem;
  }
}
