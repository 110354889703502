.tutorials {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tutorials .tutorial__container {
  margin: 3vw auto;
  width: 80%;
}

.tutorials .tutorial__container .tutorials__navbar {
  background-color: #ffff;
  width: 100%;
  border-radius: 1vw;
}

.tutorials .tutorial__container .tutorials__navbar ul {
  list-style: none;
  display: flex;
  flex-direction: row-reverse;
}

.tutorials .tutorial__container .tutorials__navbar ul li {
  margin: 1vw;
  padding: 0vw 1.5vw;
  border-radius: 2vw;
  cursor: pointer;
}

.tutorials .tutorial__container .tutorials__navbar ul li .content {
  margin: 0;
  padding: 0;
}

.tutorials .tutorial__container .tutorials__navbar ul .active {
  background-color: #efb7b5;
}

.tutorials .tutorial__container .tutorials__navbar ul .active .content {
  color: #ffff;
}

.tutorials .tutorial__container .courses__container {
  background-color: #ffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1.5vw;
  margin: 2vw auto;
}

.tutorials .tutorial__container .courses__container .course__item {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-bottom: 1px #5b5b5b88 solid;
  margin: 0vw 2vw;
  padding: 2vw 0;
  width: 90%;
}

.tutorials .tutorial__container .courses__container .course__item:last-child {
  border-bottom: none;
}

.tutorials .tutorial__container .courses__container .course__item .course__img {
  flex: 0.3;
  max-height: 15vw;
  overflow: hidden;
  border-radius: 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorials
  .tutorial__container
  .courses__container
  .course__item
  .course__img
  img {
  max-width: 100%;
  min-width: 100%;
}

.tutorials
  .tutorial__container
  .courses__container
  .course__item
  .course__content
  * {
  margin: 0.7vw 0;
  padding: 0;
}

.tutorials
  .tutorial__container
  .courses__container
  .course__item
  .course__content {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
  margin-right: 1vw;
  justify-content: space-around;
  font-family: "El Messiri", sans-serif;
}

.tutorials
  .tutorial__container
  .courses__container
  .course__item
  .course__content
  .course__title {
  font-size: 1.7vw;
  color: #5b5b5b;
}
.tutorials
  .tutorial__container
  .courses__container
  .course__item
  .course__content
  .course__description {
  font-size: 1.5vw;
  color: #5b5b5b99;
}
.tutorials
  .tutorial__container
  .courses__container
  .course__item
  .course__content
  .course__price {
  font-size: 1.6vw;
  color: #a0d1cc;
}

.tutorials
  .tutorial__container
  .courses__container
  .course__item
  .course__subscribe {
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorials
  .tutorial__container
  .courses__container
  .course__item
  .course__subscribe
  button {
  border: none;
  outline: none;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: "El Messiri", sans-serif;
  font-size: 1.7vw;
  color: #efb7b5;
  cursor: pointer;
}

.tutorials
  .tutorial__container
  .courses__container
  .course__item
  .course__subscribe
  button
  * {
  margin: 0.2vw 0;
  padding: 0;
}

.tutorials
  .tutorial__container
  .courses__container
  .course__item
  .course__subscribe
  button
  i {
  transition: all 0.3s ease;
}
.tutorials
  .tutorial__container
  .courses__container
  .course__item
  .course__subscribe
  button:hover
  i {
  transform: translateX(-0.3vw);
}

@media (max-width: 768px) {
  .tutorials .tutorial__container {
    margin: 1vw auto;
    width: 90%;
  }

  .tutorials .tutorial__container .tutorials__navbar {
    border-radius: 1rem;
  }

  .tutorials .tutorial__container .tutorials__navbar ul li {
    margin: 0.3rem;
    padding: 0vw 0.8rem;
    border-radius: 1rem;
  }

  .tutorials .tutorial__container .tutorials__navbar ul li .content {
    font-size: 1.2rem;
  }

  .tutorials .tutorial__container .courses__container {
    border-radius: 1rem;
    margin: 1rem auto;
  }

  .tutorials .tutorial__container .courses__container .course__item {
    flex-direction: column;
    margin: 0vw 1rem;
    padding: 1rem 0;
  }

  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__img {
    flex: 1;
    max-height: 25vw;
    max-width: 40vw;
    border-radius: 0.5rem;
  }

  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__content {
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
    margin-top: 0.5rem;
  }

  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__content
    .course__title {
    font-size: 1.3rem;
    color: #5b5b5b;
  }

  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__content
    .course__description {
    align-self: flex-end;
    text-align: end;
    font-size: 1.2rem;
  }
  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__content
    .course__price {
    align-self: flex-end;
    text-align: end;
    font-size: 1.2rem;
  }

  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__subscribe {
    align-self: flex-end;
  }

  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__subscribe
    button {
    flex-direction: row-reverse;
    align-items: center;
    font-size: 1.2rem;
    padding: 0;
  }

  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__subscribe
    button
    i {
    margin-right: 0.6rem;
  }
}

@media (max-width: 500px) {
  .tutorials .tutorial__container .tutorials__navbar ul li {
    margin: 0.3rem 0.1rem;
    padding: 0vw 0.3rem;
    border-radius: 0.5rem;
  }

  .tutorials .tutorial__container .tutorials__navbar ul li .content {
    font-size: 1rem;
  }

  .tutorials .tutorial__container .courses__container {
    border-radius: 0.5rem;
  }

  .tutorials .tutorial__container .courses__container .course__item {
    flex-direction: column;
    margin: 0vw 0.5rem;
    padding: 1rem 0;
  }

  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__img {
    max-height: 35vw;
    max-width: 50vw;
  }

  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__content
    .course__title {
    font-size: 1.1rem;
  }

  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__content
    .course__description {
    font-size: 1rem;
  }

  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__content
    .course__price {
    font-size: 1rem;
  }

  .tutorials
    .tutorial__container
    .courses__container
    .course__item
    .course__subscribe
    button {
    font-size: 1rem;
  }
}

.text-pink {
  color: #efb7b5;
}

.cursor-pointer {
  cursor: pointer;
}
