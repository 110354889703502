.about{
    display: flex;
    flex-direction: column;
}



.about .aboutme__section{
    display: flex;
    justify-content: center;
    align-items: center;
}

.about .aboutme__section .about__container{
    display: flex;
    flex-direction: row-reverse;
    margin: 10vw;
}

.about .aboutme__section .about__container .about__rightside{
    flex: .5;
}

.about .aboutme__section .about__container .about__rightside img {
    min-height: 30vw;
}
.about .aboutme__section .about__container .about__leftside{
    flex: .5;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-around;
    padding: 3vw;
}




.about .knowme__section{
    position: relative;
    padding: 0;
    overflow: hidden;
}

.about .knowme__section .knowme__bg{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}

.about .knowme__section .knowme__container{
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}


.about .knowme__section .knowme__container .knowme__video{
    position: relative;
    width: 70%;
    margin: auto;
    display: flex;
    min-height: 40vw;
    margin-bottom: 4vw;
    justify-content: center;
    align-items: center;
}

.about .knowme__section .knowme__container .knowme__video img{
    max-width: 100%;
    position: absolute;
    min-height: 30vw;
    top: 0;
    border-radius: .6vw;
}

.about .knowme__section .knowme__container .knowme__video .knowme__video__play{
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    max-height: 5vw;
    min-height: auto;
    border-radius: 1vw;
    opacity: .6;
}

.about .knowme__section .knowme__flower{
    position: absolute;
    max-height: 25vw;
}

.about .knowme__section #topRightFlower{
    top: 0;
    right: 0;
    transform: rotate(-45deg) translateX(10vw);
}

.about .knowme__section #bottomLeftFlower{
    bottom: 0;
    left: 0;
    transform: rotate(140deg) translateX(10vw);
}






/* WHY ME section */

.about .whyme__section .whyme__container{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: end;
    padding: 5vw;
}




/* YOU CAN section */

.youcan__section .youcan__container{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    background-color: #5B5B5B;
    justify-content: space-between;
    position: relative;
}

.youcan__section .youcan__container .youcan__rightside,
.youcan__section .youcan__container .youcan__leftside
{
    border: 1px #ffffff solid;
    flex: .5;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: 30vw;
    margin: 1vw;

}

.youcan__section .youcan__container  .youcan__items{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: .8;
    justify-content: space-around;
    flex-wrap: wrap;
}

.youcan__section .youcan__container  .youcan__items .youcan__item{
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    text-align: end;
    margin-top: 2vw;
    margin: auto 3vw;
}

.youcan__section .youcan__container  .youcan__items .youcan__item p{
    margin: 0;
    padding: 0;
    font-family: 'El Messiri', sans-serif;
    font-size: 1.6vw;
    color: #ffffff;
    margin-left: 2vw;
}

.youcan__section .youcan__container  .youcan__items .youcan__item i{
    color: #EFB7B5;
    font-size: 1.8vw;
    margin-left: 1vw;
    margin-right: 2vw;
}

.youcan__section .youcan__container .youcan__leftside > p,
.youcan__section .youcan__container .youcan__rightside > p{
    font-family: 'Lalezar', cursive;
    font-size: 2vw;
    color: #EFB7B5;
    flex: .1;
}

.youcan__section .youcan__container .youcan__leftside{
    border: none;
    background-color: #4B4B4B;
    margin: 0;
    flex: .52;
    padding-top: 2.5vw;
}

.youcan__section .youcan__container .youcan__leftside >P,
.youcan__section .youcan__container .youcan__leftside > .youcan__items{
    transform: translateY(-1.5vw);
}

.youcan__section .youcan__container .youcan__or{
    position: absolute; 
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  background-color: #5B5B5B;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw;
  max-width: 5vw;
  max-height: 5vw;
  border-radius: 50%;
}

.youcan__section .youcan__container .youcan__or > div{
    border: 1px #ffffff solid;
    padding: 0;
    min-width: 7vw;
    max-height: 7vw;
    border-radius: 50%;
    align-self: center;
    text-align: center;
}

.youcan__section .youcan__container .youcan__or P{
    color: #EFB7B5;
    font-family: 'Lalezar', cursive;
    font-size: 2vw;
    
}


/* ///////////////work with me section ///////////////////////////// */
.workwithme__section{
    position: relative;
}

.workwithme__section .workwithme__img{
    position: absolute;
    z-index: -1;
    max-width: 70vw;
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: 10vw;
}
.workwithme__section .workwithme__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw;
}


.workwithme__section .workwithme__container .workwithme__services{
    display: flex;
    flex-direction: row-reverse;
    min-width: 50vw;
    justify-content: space-around;
    margin: 4vw auto;
}





/* Subscribe section */



@media(max-width: 1050px){
    .about .aboutme__section .about__container .about__rightside img {
        max-height: 20vw;
    }
}

@media(max-width: 768px){

    /* ABOUT ME SECTION */

    .about .aboutme__section .about__container{
        flex-direction: column;
        align-items: center;
        margin: 3rem 1rem;
    }

    .about .aboutme__section .about__container .about__rightside img {
        min-height: 20rem;
    }



    /* kNOW ME SECTION */

    .about .knowme__section .knowme__container .knowme__video{
        width: 80%;
        min-height: 50vw;
    }
    
    

    /* WHY ME section */

    .about .whyme__section .whyme__container{

        padding: 1rem;
    }





    /* YOU CAN section */
    .youcan__section .youcan__container{
        flex-direction: column;
    }
    
    .youcan__section .youcan__container .youcan__rightside,
.youcan__section .youcan__container .youcan__leftside
{
    border: 1px #ffffff solid;
    min-height: 30rem;
    margin: 1rem;
    padding-bottom: 5rem;
}

.youcan__section .youcan__container  .youcan__items .youcan__item{
    align-items: flex-start;
    align-self: flex-end;
    margin-top: 1rem;
    margin: auto 1rem;
}

.youcan__section .youcan__container  .youcan__items .youcan__item p{
    font-size: 1.6rem;
    margin-left: 0;
}

.youcan__section .youcan__container  .youcan__items .youcan__item i{
    font-size: 1.8rem;
    margin-left: 1rem;
    margin-right: 0;
}

.youcan__section .youcan__container .youcan__leftside > p,
.youcan__section .youcan__container .youcan__rightside > p{
    font-size: 2rem;
}

.youcan__section .youcan__container .youcan__leftside{
    border: none;
    background-color: #4B4B4B;
    margin: 0;
    padding: 1.2rem;
    flex: .52;
    padding-top: 2.5vw;
    padding-bottom: auto;
    padding-top: 3rem;
}

.youcan__section .youcan__container .youcan__leftside >P,
.youcan__section .youcan__container .youcan__leftside > .youcan__items{
    transform: none;
}

.youcan__section .youcan__container .youcan__or{

    top: 0;
    bottom: 0;
    margin: auto;

  padding: 1rem;
  max-width: 7rem;
  max-height: 7rem;
  border-radius: 50%;
}

.youcan__section .youcan__container .youcan__or P{
    font-size: 2rem;
    
}

.youcan__section .youcan__container .youcan__or > div{
    min-width: 7rem;
    max-height: 7rem;
}


/* work with me section */

.workwithme__section .workwithme__img{
    display: none;
}

.workwithme__section .workwithme__container{

    padding: 1rem;
}




.workwithme__section .workwithme__container .workwithme__services{
    flex-direction: column;
    margin: 2rem auto;
}



}


@media(max-width: 500px){
        /* ABOUT ME SECTION */
        .about{
            text-align: center;
        }
        .about .aboutme__section .about__container .about__rightside img {
            min-height: 15rem;
        }

    
        
    /* kNOW ME SECTION */
    


    .about .knowme__section .knowme__container .knowme__video{
        width: 90%;
    }

     /* WHY ME section */




        /* YOU CAN section */
        .youcan__section .youcan__container  .youcan__items .youcan__item p{
            font-size: 1.3rem;
        }
        
        .youcan__section .youcan__container  .youcan__items .youcan__item i{
            font-size: 1.5rem;
            margin-left: .5rem;
        }

        .youcan__section .youcan__container .youcan__leftside > p,
.youcan__section .youcan__container .youcan__rightside > p{
    font-size: 1.7rem;
}



/* work with me section */


}