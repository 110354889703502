.subscribe_section{
    position: relative;
    min-height: 38vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.subscribe_section > img{
    position: absolute;
    left: 0; 
    right: 0; 
    top: 0; 
    bottom: 0; 
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.subscribe_section .about__subscribe__box{
    background-color: #ffffff;
    max-width: 55vw;
    border: .4vw #EFB7B5 solid;
    padding: 1vw;
}

.subscribe_section .about__subscribe__box .border__box > *{
    margin-left: 10vw;
}

.subscribe_section .about__subscribe__box > img{
    position: absolute;
    top: 0;
    left: 0;
    width: 37vw;
    transform: translateX(7vw);
}

.subscribe_section .about__subscribe__box .border__box{
    border: .2vw #EFB7B5 solid ;
}


@media(max-width: 768px){
    /* Subxribe section */


    .subscribe_section > img{
        display: none;
    
    }
    
    .subscribe_section .about__subscribe__box{
        background-color: #ffffff;
        max-width: 100vw;
        border: 5px #EFB7B5 solid;
        padding: 1vw;
    }
    
    .subscribe_section .about__subscribe__box .border__box > *{
        margin-left: auto;
    }
    
    .subscribe_section .about__subscribe__box > img{
        display: none;
    }
    
    .subscribe_section .about__subscribe__box .border__box{
        border: 2px #EFB7B5 solid ;
    }
    
}