.latintitle{
    color: #FFFFFF;
    font-family: 'Lexend Mega', sans-serif;
    font-size: 2.5vw;
    font-weight: 300;
    word-wrap: break-word;
    max-width: 25vw;
    text-align: center;
}



@media(max-width: 768px){
    
    .latintitle{
        font-size: 2rem;
        font-weight: 300;
        max-width: 15rem;
    }
}

@media(max-width: 500px){
    
    .latintitle{
        font-size: 1.7rem;
        font-weight: 300;
        margin-bottom: 0;
        word-wrap: break-word;
    }

}


@media(max-width: 300px){
    
    .latintitle{
        font-size: 1.4rem;
        
    }
}