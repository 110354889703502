/* description */

.home__page .description__section{
    background-color: #EFB7B5;
    padding: 3rem 4rem;
    transform: translateY(-5rem);
    z-index: 0;
}

.home__page .description__section .description__container{
    position: relative;
    padding: 5vh 4vw;
    border: 2px #ffffff solid;
}

.home__page .description__section .description__container .description__img{
    position: absolute;
    height: 13vw;
    bottom: 0;
    right: 0;
    transform: translateX(3rem);
}



.home__page .description__section .description__container .description{
    text-align: center;
    color: #ffffff;
    font-family: 'El Messiri', sans-serif;
    font-size: 1.6vw;
    line-height: 3vw;
}



@media(max-width: 768px){
    /* description */
    
.home__page .description__section{
    padding: 2rem 1rem;
    overflow: hidden;
    transform: translateY(0rem);
    margin: auto;
    position: static;
}


.home__page .description__section .description__container{
    position: static;
    padding: 1rem 1rem;
}


.home__page .description__section .description__container .description{
    text-align: end;
    font-size: 20px;
    line-height: 3rem;
}

.home__page .description__section .description__container .description__img{
    height: 160px;
    transform: translateX(2rem);
    z-index: -1;
}
}


@media(max-width: 500px){
    .home__page .description__section .description__container .description{
        font-size: 18px;
        line-height: 2.5rem;
    }
    
    .home__page .description__section .description__container .description__img{
        height: 140px;
    }
}

@media(max-width: 300px){
    .home__page .description__section .description__container{
        position: static;
        padding: .5rem ;
    }
    
    
    .home__page .description__section .description__container .description{
        font-size: 16px;
        line-height: 2rem;
    }
    
    .home__page .description__section .description__container .description__img{
        height: 120px;
    }
    
}