.coaching1_1{
    display: flex;
    flex-direction: column;
}

.coaching1_1__intro{
    background-color: #EFB7B5;
    max-height: 25vw;
    min-height: 25vw;
    overflow: hidden;
}

.coaching1_1__intro .coaching1_1__intro__container{
    display: flex;
    flex-direction: row;
    position: relative;

}

.coaching1_1__intro .coaching1_1__intro__container .coaching1_1__intro__leftside{
    flex: .3;
    
}

.coaching1_1__intro .coaching1_1__intro__container .coaching1_1__intro__leftside img{
    max-height: 33vw;
    min-height: 33vw;
}

.coaching1_1__intro .coaching1_1__intro__container .coaching1_1__intro__rightside{
    flex: .7;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    max-height: 25vw;
    min-height: 25vw;
    margin: 0 10vw;
    margin-left: 15vw;
    margin-top: 3vw;
}



/*  questions*/
.coaching1_1__questions{
    background-color: #5B5B5B;
    position: relative;
    overflow: hidden;
}

.coaching1_1__questions .white__points{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}

.coaching1_1__questions #leftPoints{
    left: 0;
    
}

.coaching1_1__questions #rightPoints{
    right: 0;
}

.coaching1_1__questions .coaching1_1__questions__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vw auto;

}


    /*  Definition*/

.coaching1_1__definition .coaching1_1__definition__container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: center;
    min-height: 35vw;
}

.coaching1_1__definition .coaching1_1__definition__container .coaching1_1__definition__leftside{
    flex: .3;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.coaching1_1__definition .coaching1_1__definition__container .coaching1_1__definition__rightside{
    flex: .55;
    margin-left: 15vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.coaching1_1__definition .coaching1_1__definition__container .coaching1_1__definition__rightside  *{
    margin: 0vw;
    padding: 0;
}

.coaching1_1__definition .coaching1_1__definition__container .coaching1_1__definition__rightside>*{
    margin: .8vw 0;
}

.coaching1_1__definition .coaching1_1__definition__container .coaching1_1__definition__leftside img{
    max-height: 25vw;
    min-height: 25vw;
}

.coaching1_1__definition .coaching1_1__definition__container .coaching1_1__definition__rightside ul{
    list-style: none;
}

.coaching1_1__definition .coaching1_1__definition__container .coaching1_1__definition__rightside ul li{
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
    margin: .5vw 0;

}

.coaching1_1__definition .coaching1_1__definition__container .coaching1_1__definition__rightside ul li i{
    margin-left: 5vw;
    color: #EBC984;
    font-size: 1.5vw;
    
}


/* youwillbe__able */

.youwillbe__able{
    display: flex;
    flex-direction: column;
    align-items: center;
}




/* isupport__you */
.isupport__you{
    margin: 5vw auto;
}
.isupport__you__container{
    display: flex;
    flex-direction: row-reverse;
    margin: auto 6vw;
}

.isupport__you__container .isupport__you__leftside{

}

.isupport__you__container .isupport__you__leftside img{
    max-height: 27vw;
    min-height: 27vw;
}

.isupport__you__container .isupport__you__rightside{
    margin: auto 5vw;
}

.isupport__you__container .isupport__you__rightside >*{
    margin: 0;
    padding: 0;
}

.isupport__you__container .isupport__you__rightside ul{
    list-style: none;
}

.isupport__you__container .isupport__you__rightside ul *{
    margin: 0;
    padding: 0;
}

.isupport__you__container .isupport__you__rightside ul li{
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
    margin: 1.2vw 0;

}

.isupport__you__container .isupport__you__rightside ul li i {
    margin-left: 3vw;
    color: #EBC984;
    font-size: 1.5vw;
}



/* getwhatyouwant__section */
.getwhatyouwant__section{
    display: flex;
    flex-direction: column;
}

.getwhatyouwant__section .getwhatyouwant__row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 3vw 6vw;
    margin-left: 3vw;
    align-items: center;
    
}

.getwhatyouwant__section .getwhatyouwant__row > div *{
    margin: 0;
    padding: 0;
}

.getwhatyouwant__section .getwhatyouwant__row .row1__leftside{
    flex: 1;
    margin-left: 6vw;
}

.getwhatyouwant__section .getwhatyouwant__row .row1__leftside img{
    max-height: 25vw;
    min-height: 25vw;
}

.getwhatyouwant__section .getwhatyouwant__row .row1__rightside{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 6vw;
}

.getwhatyouwant__section .getwhatyouwant__row .row1__rightside >*{
    margin: .5vw 0;
}

.getwhatyouwant__section .getwhatyouwant__row .row1__rightside .getwhatyouwant__title{
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
}

.getwhatyouwant__section .getwhatyouwant__row .row1__rightside .getwhatyouwant__title i {
    margin-left: 2vw;
    color: #EBC984;
    font-size: 1.5vw;
}

.getwhatyouwant__section .getwhatyouwant__row .row1__rightside .getwhatyouwant__content{
    list-style: none;
    margin-right: 4vw;
    
}

.getwhatyouwant__section .getwhatyouwant__row .row1__rightside .getwhatyouwant__content li {
    margin: .5vw 0;
}





@media(max-width: 768px){
    .coaching1_1__intro{
        max-height: none;
        min-height: none;
        overflow: visible;
    }

    
    .coaching1_1__intro .coaching1_1__intro__container{
        flex-direction: column;
        align-items: center;
    
    }

    .coaching1_1__intro .coaching1_1__intro__container .coaching1_1__intro__leftside{
        flex: 1;
        
    }
    
    .coaching1_1__intro .coaching1_1__intro__container .coaching1_1__intro__leftside img{
        max-height: 18rem;
        min-height: 18rem;
        transform: translateY(-2rem);
    }

    .coaching1_1__intro .coaching1_1__intro__container .coaching1_1__intro__rightside{
        flex: 1;
        margin-top: 0rem;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 2rem;

    }


    .coaching1_1__intro .coaching1_1__intro__container .coaching1_1__intro__rightside *{
        text-align: center !important;
    }
    
    /*  questions*/

    .coaching1_1__questions *{
        text-align: center !important;
    }
    
    .coaching1_1__questions #leftPoints{
        left: -25%;
        
    }
    
    .coaching1_1__questions #rightPoints{
        right: -25%;
    }

  /*  Definition*/
  .coaching1_1__definition .coaching1_1__definition__container .coaching1_1__definition__leftside{
    display: none;
}

.coaching1_1__definition .coaching1_1__definition__container .coaching1_1__definition__rightside{
    flex: .9;
    margin-left: 0;
    margin-top: 2rem;
}

.coaching1_1__definition .coaching1_1__definition__container .coaching1_1__definition__rightside ul{

    margin-right: 1rem;
}


/* youwillbe__able */
.youwillbe__able{
    align-items: center;
    margin: auto .5rem;
}

.youwillbe__able *{
    text-align: center !important;
}
    

/* isupport__you */
.isupport__you__container .isupport__you__leftside{
    display: none;
}

.isupport__you__container .isupport__you__rightside{
    margin: auto .5rem;
}

.isupport__you__container .isupport__you__rightside ul li{

    margin: 1rem 0;

}

.isupport__you__container .isupport__you__rightside ul li i {
    margin-left: 1rem;
    color: #EBC984;
    font-size: 1.2rem;
}


/* getwhatyouwant__section */
.getwhatyouwant__section .getwhatyouwant__row{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1rem;
    margin-left: 0;
    align-items: flex-end;
    
}

.getwhatyouwant__section .getwhatyouwant__row .row1__leftside{
    flex: 1;
    align-self: center;
    margin-left: 0;
}

.getwhatyouwant__section .getwhatyouwant__row .row1__leftside img{
    max-height: 18rem;
    min-height: 18rem;
}

.getwhatyouwant__section .getwhatyouwant__row .row1__rightside{

    margin-left: 0;
    margin-top: 2rem;
}

.getwhatyouwant__section .getwhatyouwant__row .row1__rightside .getwhatyouwant__title i {
    margin-left: 1rem;
    font-size: 1.2rem;
}

.getwhatyouwant__section .getwhatyouwant__row .row1__rightside .getwhatyouwant__content{
    list-style: none;
    margin-right: 2rem;
    
}



}


@media(max-width: 500px){
    .coaching1_1__intro .coaching1_1__intro__container .coaching1_1__intro__leftside img{
        max-height: 15rem;
        min-height: 15rem;
        transform: translateY(-1rem);
    }


    .getwhatyouwant__section .getwhatyouwant__row .row1__leftside img{
        max-height: 15rem;
        min-height: 15rem;
    }

    .isupport__you__container .isupport__you__rightside ul li i {
        margin-left: .8rem;
        color: #EBC984;
        font-size: 1rem;
    }
  .getwhatyouwant__section .getwhatyouwant__row .row1__rightside .getwhatyouwant__title i {
        margin-left: .8rem;
        font-size: 1rem;
    }
    
    
}

@media(max-width: 300px){
    .coaching1_1__intro .coaching1_1__intro__container .coaching1_1__intro__leftside img{
        max-height: 15rem;
        min-height: 15rem;
    }

    .coaching1_1__intro .coaching1_1__intro__container .coaching1_1__intro__rightside{
        margin-top: 2rem;
       
        margin-bottom: 5rem;

    }

    .isupport__you__container .isupport__you__rightside ul li i {
        margin-left: .6rem;
        color: #EBC984;
        font-size: .9rem;
    }

    .getwhatyouwant__section .getwhatyouwant__row .row1__leftside img{
        max-height: 13rem;
        min-height: 13rem;
    }

    .getwhatyouwant__section .getwhatyouwant__row .row1__rightside .getwhatyouwant__title i {
        margin-left: .6rem;
        font-size: .9rem;
    }
    
    


}