/* who am i section */

.home__page .whoami__section .whoami__container {
  display: flex;
  flex-direction: row-reverse;
  padding: auto 2vw;
}

.home__page .whoami__section .whoami__container .whoami__img {
  margin-right: 3vw;
  position: relative;
}

.home__page .whoami__section .whoami__container .whoami__img img {
  max-height: 35vw;
  min-height: 30vw;
}

.home__page .whoami__section .whoami__container .whoami__img .whoami {
  background-color: #a0d1cc;
  font-family: "Lalezar", cursive;
  font-size: 2vw;
  color: #ffffff;
  padding: 0.2vw 4vw;
  position: absolute;
  top: 5vw;

  left: -7vw;
}

.home__page .whoami__section .whoami__container .whoami__content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: end;
  min-height: 18vw;
  align-self: flex-end;
  justify-content: space-around;
  margin-bottom: 4vw;
  width: 45%;
}

.home__page
  .whoami__section
  .whoami__container
  .whoami__content
  .whoami__description {
  font-family: "El Messiri", sans-serif;
  color: #5b5b5b;
  font-size: 1.8vw;
}

.home__page
  .whoami__section
  .whoami__container
  .whoami__content
  .whoami__readmore__btn {
  font-family: "El Messiri", sans-serif;
  color: #efb7b5;
  font-size: 1.5vw;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home__page
  .whoami__section
  .whoami__container
  .whoami__content
  .whoami__readmore__btn
  .fa {
  margin-right: 1vw;
  transition: all 0.3s ease;
}

.home__page
  .whoami__section
  .whoami__container
  .whoami__content
  .whoami__readmore__btn:hover
  .fa {
  transform: translateX(-3px);
}

@media (max-width: 768px) {
  /* who am i section */
  .home__page .whoami__section {
    position: relative;
  }

  .home__page .whoami__section .whoami__container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .home__page .whoami__section .whoami__container .whoami__img {
    margin-right: 0;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    position: relative;
    transform: translateX(3rem);
  }

  .home__page .whoami__section .whoami__container .whoami__img img {
    max-height: 70vh;
    min-height: 70vh;
  }

  .home__page .whoami__section .whoami__container .whoami__img .whoami {
    font-size: 22px;
    padding: 0.3rem 3rem;
    align-self: center;
    transform: translateY(0);
    position: relative;
  }

  .home__page .whoami__section .whoami__container .whoami__content {
    align-items: center;
    text-align: center;
    min-height: 35vh;
    align-self: center;
    margin-bottom: 5vh;
    width: 80%;
  }

  .home__page
    .whoami__section
    .whoami__container
    .whoami__content
    .whoami__description {
    font-size: 24px;
  }

  .home__page
    .whoami__section
    .whoami__container
    .whoami__content
    .whoami__readmore__btn {
    font-size: 22px;
  }

  .home__page
    .whoami__section
    .whoami__container
    .whoami__content
    .whoami__readmore__btn
    .fa {
    margin-right: 15px;
  }
}

@media (max-width: 500px) {
  .home__page .whoami__section .whoami__container .whoami__img .whoami {
    font-size: 20px;
    padding: 0.2rem 2rem;
  }

  .home__page
    .whoami__section
    .whoami__container
    .whoami__content
    .whoami__description {
    font-size: 18px;
  }

  .home__page
    .whoami__section
    .whoami__container
    .whoami__content
    .whoami__readmore__btn {
    font-size: 18px;
  }

  .home__page .whoami__section .whoami__container .whoami__img img {
    max-height: 20rem;
    min-height: 20rem;
  }

  .home__page .whoami__section .whoami__container .whoami__img {
    transform: translateX(1rem);
  }
}

@media (max-width: 300px) {
  .home__page .whoami__section .whoami__container .whoami__img .whoami {
    font-size: 19px;
    padding: 0.2rem 2rem;
  }

  .home__page
    .whoami__section
    .whoami__container
    .whoami__content
    .whoami__description {
    font-size: 16px;
  }

  .home__page
    .whoami__section
    .whoami__container
    .whoami__content
    .whoami__readmore__btn {
    font-size: 16px;
  }

  .home__page .whoami__section .whoami__container .whoami__img img {
    max-height: 16rem;
    min-height: 16rem;
  }

  .home__page .whoami__section .whoami__container .whoami__img {
    transform: translateX(0.5rem);
  }
}
