.blog{
    display: flex;
    flex-direction: column;
}

.blog__container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blog__container .instagram8_account{
    margin: 2vw auto;
    font-size: 2vw;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #5B5B5B;
}

.blog__container .instagram__pics{
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1vw;
}

.blog__container .instagram__pics img {
    max-width: 22vw;
    max-height: 22vw;
    height: 20vw;
}

.blog__container .seemore__btn{
    margin: 5vw auto;
}

.blog__container .seemore__btn button{
    border: none;
    outline: none;
    cursor: pointer;
    background-color: #A0D1CC;
    padding: .5vw 2vw;
    border-radius: 10vw;
    color: #ffffff;
    font-family: 'Lalezar', cursive;
    font-size: 1.5vw;
}


.youtube__section{
    background-color: #5B5B5B;
    position: relative;
    min-height: 20vw;
}

.youtube__section .leaf{
    position: absolute;
    right: 0;
    margin-right: 5vw;
    opacity: .3;
    height: 20vw;
}

.youtube__section .youtube__container{
    display: flex;
    flex-direction: row-reverse;
    margin: auto 5vw;
}

.youtube__section .youtube__container .youtube__rightside{
    flex: .4;
}

.youtube__section .youtube__container .youtube__rightside img{
    max-height: 18vw;
    min-height: 18vw;
    transform: translateY(-4vw);
}

.youtube__section .youtube__container .youtube__leftside{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: start;
    text-align: end;
    flex: .6;
    z-index: 1;
}

@media(max-width: 1050px){
    .blog__container .instagram__pics{
       
        grid-template-columns: 1fr 1fr 1fr;
       
    }

    .blog__container .instagram__pics img {
        max-width: 30vw;
        width: 30vw;
        max-height: 30vw;
        height: 25vw;
    }
}


@media(max-width: 768px){
    .blog__container .instagram8_account{
        margin: 2rem auto;
        font-size: 2rem;

    }

    .blog__container .instagram__pics{
       
        grid-template-columns: 1fr 1fr;
       
    }

    
    .blog__container .instagram__pics img {
        max-width: 40vw;
        width: 40vw;
        max-height: 40vw;
        height: 35vw;
    }

    .blog__container .seemore__btn{
        margin: 5rem auto;
    }
    

    .blog__container .seemore__btn button{
        border: none;
        outline: none;
        cursor: pointer;
        background-color: #A0D1CC;
        padding: .5rem 2rem;
        border-radius: 10rem;
        color: #ffffff;
        font-family: 'Lalezar', cursive;
        font-size: 1.5rem;
    }

    .youtube__section .leaf{
        display: none;
    }


    .youtube__section{
        background-color: #5B5B5B;
        position: relative;
        min-height: 30rem;
    }


    .youtube__section .youtube__container{
        flex-direction: column-reverse;
        align-items: center;
        margin: auto 1rem;
    }
    
    .youtube__section .youtube__container .youtube__rightside img{
        max-height: 60vw;
        transform: translateY(-4rem);
    }

    .youtube__section .youtube__container .youtube__leftside{
        transform: translateY(-2rem);
        justify-content: space-between;
        flex: .7;
    }
    
}


@media(max-width: 500px){
    .blog__container .instagram8_account{
        margin: 1.5rem auto;
        font-size: 1.8rem;

    }

    .blog__container .instagram__pics{
       
        grid-template-columns: 1fr;
       grid-gap: 1rem;
    }

    .blog__container .instagram__pics img {
        max-width: 80vw;
        width: 80vw;
        max-height: 80vw;
        height: 70vw;
    }

    .youtube__section .youtube__container .youtube__rightside img{
        max-height: 60vw;
        transform: translateY(-4rem);
    }

}

