.footer__container {
  display: flex;
  flex-direction: column;
}

.footer__container .footer__menu {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  justify-content: space-around;
}

.footer__container .footer__menu .footer__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: "El Messiri", sans-serif;
}

.footer__container .footer__menu .footer__right p {
  color: #a0d1cc;
  text-align: end;
  font-size: 1.6vw;
}

.footer__container .footer__menu .footer__right ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3vw;
  text-align: end;
  list-style: none;
  font-size: 1.3vw;
}

.footer__container .footer__menu .footer__right ul span {
  color: #5b5b5b;
  text-decoration: none;
  cursor: pointer;
}

.footer__container .footer__menu .footer__middle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__container .footer__menu .footer__middle img {
  width: 15vw;
}

.footer__container .footer__menu .footer__left {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: "El Messiri", sans-serif;
}

.footer__container .footer__menu .footer__left ul {
  list-style: none;
}

.footer__container .footer__menu .footer__left ul a {
  text-decoration: none;
}

.footer__container .footer__menu .footer__left ul li {
  margin: 2vh;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  color: #5b5b5b;
  font-size: 1.6vw;
}

.footer__container .footer__menu .footer__left ul li img {
  height: 3vh;
  width: 3vh;
  margin-left: 3vw;
}

.footer__container .footer__copyright {
  font-family: "El Messiri", sans-serif;

  background-color: #efb7b5;
  text-align: center;
  color: #ffffff;
}

.footer__container .footer__copyright p {
  margin: 1.5vh auto;
  font-size: 1.3vw;
}

@media (max-width: 1050px) {
  .footer__container .footer__menu .footer__right p {
    font-size: 20px;
  }

  .footer__container .footer__menu .footer__right ul {
    grid-gap: 1.4rem;
    font-size: 15px;
  }

  .footer__container .footer__copyright p {
    margin: 0.5rem auto;
    font-size: 1rem;
  }

  .footer__container .footer__menu .footer__left ul li img {
    height: 1.5rem;
    width: 1.5rem;
  }
}

@media (max-width: 768px) {
  .footer__container .footer__menu {
    flex-direction: row;
  }

  .footer__container .footer__menu .footer__right {
    display: none;
  }

  .footer__container .footer__menu .footer__left ul li {
    margin: 1rem;

    font-size: 15px;
  }

  .footer__container .footer__menu .footer__middle img {
    width: 10rem;
  }
}

@media (max-width: 500px) {
  .footer__container .footer__menu .footer__middle img {
    width: 4rem;
  }

  .footer__container .footer__copyright p {
    margin: 0.5rem auto;
    font-size: 15px;
  }

  .footer__container .footer__menu .footer__left ul li img {
    height: 1rem;
    width: 1rem;
    margin-left: 3vw;
  }
}
