.home__page{
    border-top: 1rem #EFB7B5 solid;
    overflow: hidden;
}



/* subsribing modal */

.home__page .subscribe__modal{
  
    display: none;
}

.home__page .subscribe__modal.visible{
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #00000066;
    z-index: 10;
    
    align-items: flex-end;
    display: flex;
}

.home__page .subscribe__modal .modal__content {
    display: flex;
    flex-direction: row-reverse;
    background-color: #ffffff;
    max-height: 35vw;
    max-width: 70vw;
    margin: auto;
}

.home__page .subscribe__modal .modal__content .modal__right
{
    flex: .5;
}

.home__page .subscribe__modal .modal__content .modal__right{
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 0;
}
.home__page .subscribe__modal .modal__content .modal__right img{
    max-width: 100%;
    min-width: 100%;
    

}





@media(max-width: 768px){
   



.home__page .subscribe__modal .modal__content {
    flex-direction: column;
    max-height: 80vh;
    max-width: 85vw;
}

.home__page .subscribe__modal .modal__content .modal__right img{
    /* max-height: 100%; */
    /* min-height: 100%; */
    max-width: auto;
    min-width: 100%;
    

}

.home__page .subscribe__modal .modal__content .modal__right
{
    flex: auto;
    max-height: 50%;
    max-height: 50%;
}


    
}
