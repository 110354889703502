.course__content__1 {
  display: flex;
  flex-direction: column;
}

.course__content__1 .title,
.course__content__1 .content {
  margin: 0;
  padding: 0;
}

.course__content__1 .course__content__1__container {
  width: 90%;
  margin: auto;
  margin-bottom: 2vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: 50vh;
  margin-top: 0;
}

.course__content__1 .course__content__leftside {
  flex: 0.6;
  display: flex;
  flex-direction: column;
  margin: 0 2vw;
}

.course__content__1 .course__content__leftside .course__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 2vw;
}

.course__content__1
  .course__content__leftside
  .course__item
  .course__section__title {
  display: flex;
  flex-direction: row-reverse;
  color: black;
  font-size: 2vw;
  align-items: center;
  margin-bottom: 1.5vw;
}

.course__content__1
  .course__content__leftside
  .course__item
  .course__section__title
  img {
  max-width: 2vw;
  max-height: 2vw;
  margin: 0;
  margin-left: 2vw;
}

.course__video {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1vw;
  overflow: hidden;
}

.course__video .video {
  max-width: 100%;
  min-width: 100%;
  z-index: -1;
}

.course__video .play {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 5vw;
}

.course__content__1 .course__content__leftside .course__item .course__memos,
.course__content__1
  .course__content__leftside
  .course__item
  .course__memos
  .course__memos__item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1vw;
}

.course__content__1
  .course__content__leftside
  .course__item
  .course__memos
  .course__memos__item
  .content {
  margin-bottom: 0.5vw;
}

.course__content__1
  .course__content__leftside
  .course__item
  .course__memos
  .course__memos__item
  .memo {
  background-color: #fff;
  border: 0.2vw #efb7b5 solid;
  border-radius: 0.3vw;
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0.5vw auto;
  min-height: 2.5vw;
  align-items: center;
}

.course__content__1
  .course__content__leftside
  .course__item
  .course__memos
  .course__memos__item
  .memo
  > i {
  color: #efb7b5;
  font-size: 2vw;
  margin-left: 1.5vw;
  cursor: pointer;
}
.course__content__1
  .course__content__leftside
  .course__item
  .course__memos
  .course__memos__item
  .memo
  > div {
  border-top: 0.2vw #efb7b5 solid;
  width: 100%;
  height: 0;
  padding: 0;
  margin: auto 1.5vw;
}

.course__content__1
  .course__content__leftside
  .course__item
  .course__attachments {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.course__content__1
  .course__content__leftside
  .course__item
  .course__attachments
  .course__attachment__item {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 1.5vw;
  align-items: center;
  min-height: 2.5vw;
  border-radius: 0.3vw;
  border: 0.2vw #efb7b5 solid;
}

.course__content__1
  .course__content__leftside
  .course__item
  .course__attachments
  .course__attachment__item
  .content {
  font-size: 1.2vw;
  color: #efb7b5;
  margin: auto 1.5vw;
}

.course__content__1
  .course__content__leftside
  .course__item
  .course__attachments
  .course__attachment__item
  .course__attachment__download {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.5vw;
}

.course__content__1
  .course__content__leftside
  .course__item
  .course__attachments
  .course__attachment__item
  .course__attachment__download
  i {
  font-size: 1.5vw;
  color: #efb7b5;
}

.course__content__1 .course__content__rightside {
  flex: 0.4;
  margin: 0 2vw;
}

.course__program__container {
  display: flex;
  flex-direction: column;
  user-select: none;
  margin-top: 1vw;
}

.course__program__container .course__title .course__title__container {
  background-color: #5b5b5b;
  border: 0.15vw #efb7b5 solid;
  border-radius: 0.4vw;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  padding: 0.5vw 2vw;
  cursor: pointer;
}

.course__program__container .course__title .course__title__container i {
  color: #fff;
  font-size: 1.8vw;
  margin-left: 2vw;
}

.course__program__container .active__course__title .course__title__container i {
  transform: rotate(-90deg);
}

.course__program__container .course__title .course__items__container {
  margin-right: 2vw;
  display: none;
}

.course__program__container .active__course__title .course__items__container {
  display: block;
}

.course__program__container .course__subtitle {
  margin: 0.5vw 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.course__program__container .active__course__subtitle .content {
  color: #efb7b5;
}

.course__program__container .course__subtitle > div {
  height: 1vw;
  width: 1vw;
  background-color: #5b5b5b;
  margin-left: 1vw;
}

@media (max-width: 768px) {
  .course__content__1 .course__content__1__container {
    flex-direction: column;
    margin-bottom: 1.5rem;
  }

  .course__content__1 .course__content__leftside {
    flex: 1;
    margin: auto;
  }

  .course__content__1 .course__content__leftside .course__item,
  .course__content__1
    .course__content__leftside
    .course__item
    .course__section__title {
    margin-bottom: 1rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__section__title
    img {
    max-width: 1.3rem;
    max-height: 1.3rem;

    margin-left: 0.8rem;
  }

  .course__video {
    margin-top: 0;
    border-radius: 1rem;
  }

  .course__video .play {
    max-width: 3rem;
    min-width: 3rem;
  }

  .course__content__1 .course__content__leftside .course__item .course__memos,
  .course__content__1
    .course__content__leftside
    .course__item
    .course__memos
    .course__memos__item {
    margin-bottom: 0.8rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__memos
    .course__memos__item
    .content {
    margin-bottom: 0.3rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__memos
    .course__memos__item
    .memo {
    background-color: #fff;
    border: 0.1rem #efb7b5 solid;
    border-radius: 0.2rem;

    padding: 0.3rem auto;
    min-height: 1.8rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__memos
    .course__memos__item
    .memo
    > i {
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__memos
    .course__memos__item
    .memo
    > div {
    border-top: 0.2rem #efb7b5 solid;
    margin: auto 1rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__attachments
    .course__attachment__item {
    margin-bottom: 1rem;
    min-height: 2rem;
    border-radius: 0.2rem;
    border: 0.1rem #efb7b5 solid;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__attachments
    .course__attachment__item
    .content {
    font-size: 1rem;
    margin: auto 1rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__attachments
    .course__attachment__item
    .course__attachment__download {
    margin-left: 1rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__attachments
    .course__attachment__item
    .course__attachment__download
    i {
    font-size: 1rem;
  }

  .course__content__1 .course__content__rightside {
    flex: 1;
    margin: 0;
    width: 100%;
  }

  .course__program__container {
    margin-top: 1rem;

    max-height: 30rem;
    overflow: scroll;
    padding-right: 1rem;
    scroll-behavior: smooth;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #5b5b5b44;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #5b5b5b;
    border-radius: 10px;
  }

  .course__program__container .course__title .course__title__container {
    border-radius: 0.3rem;
    padding: 0.2rem 1rem;
  }

  .course__program__container .course__title .course__title__container i {
    font-size: 1.5rem;
    margin-left: 1.5rem;
  }

  .course__program__container .course__title .course__items__container {
    margin-right: 2rem;
  }

  .course__program__container .course__subtitle {
    margin: 0.5rem 0;
  }

  .course__program__container .course__subtitle > div {
    height: 1rem;
    width: 1rem;
    margin-left: 1rem;
  }
}

@media (max-width: 500px) {
  .course__content__1 .course__content__1__container {
    margin-bottom: 1rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__section__title,
  .course__content__1 .course__content__leftside .course__item {
    margin-bottom: 0.8rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__section__title
    img {
    max-width: 1rem;
    max-height: 1rem;

    margin-left: 0.8rem;
  }

  .course__video {
    border-radius: 0.8rem;
  }

  .course__video .play {
    max-width: 2.5rem;
    min-width: 2.5rem;
  }

  .course__content__1 .course__content__leftside .course__item .course__memos,
  .course__content__1
    .course__content__leftside
    .course__item
    .course__memos
    .course__memos__item {
    margin-bottom: 0.6rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__memos
    .course__memos__item
    .memo {
    min-height: 1.7rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__memos
    .course__memos__item
    .memo
    > i {
    font-size: 1.3rem;
    margin-left: 0.8rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__memos
    .course__memos__item
    .memo
    > div {
    border-top: 0.15em #efb7b5 solid;
    margin: auto 0.8rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__attachments
    .course__attachment__item {
    margin-bottom: 1rem;
    min-height: 1.8rem;
    border-radius: 0.2rem;
    border: 0.1rem #efb7b5 solid;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__attachments
    .course__attachment__item
    .content {
    font-size: 0.8rem;
    margin: auto 0.8rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__attachments
    .course__attachment__item
    .course__attachment__download {
    margin-left: 0.8rem;
  }

  .course__content__1
    .course__content__leftside
    .course__item
    .course__attachments
    .course__attachment__item
    .course__attachment__download
    i {
    font-size: 0.8rem;
  }

  .course__program__container .course__title .course__title__container {
    border-radius: 0.2rem;
    padding: 0.1rem 0.8rem;
  }

  .course__program__container .course__title .course__title__container i {
    font-size: 1.2rem;
    margin-left: 1.2rem;
  }

  .course__program__container .course__title .course__items__container {
    margin-right: 1.5rem;
  }

  .course__program__container .course__subtitle {
    margin: 0.2rem 0;
  }

  .course__program__container .course__subtitle > div {
    height: 0.8rem;
    width: 0.8rem;
    margin-left: 0.8rem;
  }
}
