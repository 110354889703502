.coaching__subscribe__btn{
    background-color: #EBC984;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: none;
    outline: none;
    padding: 0;
    max-width: 15vw;
    align-self: center;
    padding: .5vw 2vw;
    border-radius: 1vw;
    margin: 5vw auto;
    cursor: pointer;
}

.coaching__subscribe__btn * {
    padding: 0;
    margin: 0;
}

@media(max-width: 768px){

    .coaching__subscribe__btn{
        max-width: 50vw;
        padding: .5rem 2rem;
        border-radius: 1rem;
        margin: 2rem auto;
    }
}
