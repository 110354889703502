/* testimonial section */

.testimonial__section {
  position: relative;
  min-height: 25vw;
}

.testimonial__section .testimonial__container {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: auto;
  justify-content: space-between;
}

.testimonial__section .testimonial__container .tetsimonial__buttons {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 30%;
  width: 80%;
  margin: auto;
  justify-content: space-between;
}

.testimonial__section .testimonial__container .testimonial__button__container {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 4vw;
}

.testimonial__section
  .testimonial__container
  .testimonial__button__container
  p {
  font-family: "Lalezar", cursive;
  color: #efb7b5;
  font-size: 1.3vw;
}
.testimonial__section
  .testimonial__container
  .testimonial__button__container
  .testimonial__button {
  background-color: #5b5b5b;
  color: #efb7b5;
  border: none;
  outline: none;
  padding: 1rem;
  font-size: 3.8vw;
  height: 4vw;
  width: 4vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 50%;
}

.testimonial__section .testimonial__container .testimonial__button #left {
  margin: -1rem auto;
  transform: translateX(0.1vw);
}

.testimonial__section .testimonial__container .testimonial__button #right {
  margin: -1rem auto;
  transform: translateX(-0.1vw);
}

.testimonial__section .testimonial__container .testimonial__opinion {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 20vw;
  margin: auto;
  justify-content: space-between;
  flex: 0.8;
}

.testimonial__section
  .testimonial__container
  .testimonial__opinion
  .opinion__img {
  border: 0.1vw #efb7b5 solid;
  padding: 0.4vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial__section
  .testimonial__container
  .testimonial__opinion
  .opinion__img
  img {
  height: 6.3vw;
}

.testimonial__section
  .testimonial__container
  .testimonial__opinion
  .opinion__text {
  text-align: center;
  font-size: 1.6vw;
  font-family: "El Messiri", sans-serif;
  color: #5b5b5b;
}

.testimonial__section
  .testimonial__container
  .testimonial__opinion
  .opinion__name {
  font-family: "Lalezar", cursive;
  color: #efb7b5;
  font-size: 1.4vw;
}

.testimonial__section .testimonial__quotes {
  position: absolute;
  top: 25%;
  right: 20%;
  width: 8vw;
  z-index: -1;
}

@media (max-width: 768px) {
  /* testimonial section */

  .testimonial__section .testimonial__container {
    width: 90%;
    flex-direction: column;
    margin: 2rem auto;
  }

  .testimonial__section .testimonial__container .tetsimonial__buttons {
    position: relative;
    width: 40%;
    margin-bottom: 1rem;
  }

  .testimonial__section
    .testimonial__container
    .testimonial__button__container
    p {
    font-family: "Lalezar", cursive;
    color: #efb7b5;
    font-size: 15px;
    margin: 0;
  }

  .testimonial__section
    .testimonial__container
    .testimonial__button__container
    .testimonial__button {
    font-size: 40px;
    height: 40px;
    width: 40px;
    padding: 1.5rem;
  }

  .testimonial__section .testimonial__container .testimonial__button #left {
    margin: -1.3rem auto;
    transform: translateX(4px);
  }

  .testimonial__section .testimonial__container .testimonial__button #right {
    margin: -1.3rem auto;
    transform: translateX(-4px);
  }

  .testimonial__section .testimonial__container .testimonial__opinion {
    width: 90%;
  }

  .testimonial__section
    .testimonial__container
    .testimonial__opinion
    .opinion__img {
    border: 1px #efb7b5 solid;
    padding: 0.2rem;
    border-radius: 50%;
  }

  .testimonial__section
    .testimonial__container
    .testimonial__opinion
    .opinion__img
    img {
    height: 6rem;
  }

  .testimonial__section
    .testimonial__container
    .testimonial__opinion
    .opinion__text {
    font-size: 22px;
  }

  .testimonial__section
    .testimonial__container
    .testimonial__opinion
    .opinion__name {
    font-size: 20px;
    margin: auto;
    margin-bottom: 1rem;
  }

  .testimonial__section .testimonial__quotes {
    display: none;
  }
}

@media (max-width: 500px) {
  .testimonial__section
    .testimonial__container
    .testimonial__opinion
    .opinion__img
    img {
    height: 6rem;
  }

  .testimonial__section
    .testimonial__container
    .testimonial__opinion
    .opinion__text {
    font-size: 20px;
  }

  .testimonial__section {
    padding-bottom: 20px;
  }
}

@media (max-width: 300px) {
  .testimonial__section
    .testimonial__container
    .testimonial__opinion
    .opinion__img
    img {
    height: 5rem;
  }

  .testimonial__section
    .testimonial__container
    .testimonial__opinion
    .opinion__text {
    font-size: 18px;
  }

  .testimonial__section
    .testimonial__container
    .testimonial__opinion
    .opinion__name {
    font-size: 18px;
  }

  .testimonial__section .testimonial__container .tetsimonial__buttons {
    width: 70%;
  }

  .testimonial__section {
    padding-bottom: 20px;
  }
}
