.title{
    font-family: 'Lalezar', cursive;
    font-size: 1.8vw;
    text-align: end;
    color: #EFB7B5;
}

.big{
    font-size: 2.5vw;
}

@media(max-width: 768px){

    .title{
        font-size: 1.7rem;
    
    }

    .big{
        font-size: 1.8rem;
    }

}

@media(max-width: 500px){

    .title{
        font-size: 1.4rem;
    }

    .big{
        font-size: 1.7rem;
    }


}

@media(max-width: 300px){

    .title{
        font-size: 1.1rem;
    }

    .big{
        font-size: 1.4rem;
    }


}